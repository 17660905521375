import * as React from "react";
import Box from "@mui/material/Box";

import { useState } from "react";
import BasicInfo from "./BasicInfo";
import Payments from "./Payments";
import Google from "./Google";
import AirBnb from "./AirBnb";
import Commissions from "./Commissions";
import AdvanceCmLog from "./AdvanceCmLog";
import CmLog from "./CmLog";
import UsageLog from "./UsageLog";
import Transfers from "./Transfers";
import FlexCollectLog from "./FlexCollectLog";
import Features from "./Features";
import Subscriptions from "./Subscriptions";
import Channels from "./Channels";
import PerfStats from "./PerfStats";
import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  handleDjangoDataRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentPaste from "@mui/icons-material/ContentPaste";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HubIcon from "@mui/icons-material/Hub";
import PaymentsIcon from "@mui/icons-material/Payments";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import BookIcon from "@mui/icons-material/Book";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import InfoIcon from "@mui/icons-material/Info";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PatternIcon from "@mui/icons-material/Pattern";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CategoryIcon from "@mui/icons-material/Category";
import RedeemIcon from "@mui/icons-material/Redeem";
import GoogleIcon from "@mui/icons-material/Google";
import { handleparamValue } from "../../utils/Utils";
import GroupFeatures from "./GroupFeatures";
import GHAReport from "./GHAReport";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import GroupReplenish from "./GroupReplenish";
import AttractionsIcon from "@mui/icons-material/Attractions";
import AutoReplenish from "./AutoReplenish";

import CompetitorRate from "./CompetitorRate";
import CompareIcon from "@mui/icons-material/Compare";
import HandymanIcon from "@mui/icons-material/Handyman";
import STRConfiguration from "./STRConfiguration";
import AddchartIcon from "@mui/icons-material/Addchart";
import AddonsPricing from "./AddonsPricing";
import { toast } from "react-toastify";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";

export default function Index() {
  const hotelId = handleparamValue("hotel_id");
  const groupId = handleparamValue("groupId");
  const dashboard = handleparamValue("dashboard");
  const emailId = handleparamValue("emailId");
  const access = JSON.parse(localStorage.getItem("metaAccessControl"));
  // console.log(access);
  //---------------------------------------
  const [value, setValue] = useState(dashboard === "group" ? "group" : "basic");
  const [basicInfo, setbasicInfo] = useState();
  const [refresh, setRefresh] = useState();
  const [menuStyle, setmenuStyle] = useState({
    basic: "primary",
    channels: "",
    payments: "",
    transfers: "",
    features: "",
    subscription: "",
    log: "",
    usageLog: "",
    cmLog: "",
    advanceCmLog: "",
    oldAdvanceCmLog: "",
    flexCollectLog: "",
    commissions: "",
    perfStats: "",
    airbnb: "",
    google: "",
    group: "primary",
    gha: "",
    groupReplenish: "",
    replenish: "",
    competitorRate: "",
    str: "",
    pricingAddon: "",
    subscriptionsInfo: "",
    misc: "",
  });

  //----Basic Info
  useEffect(() => {
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaAdminManageHotel/?hotel_id=${hotelId}`
    )
      .then((data) => setbasicInfo(data))
      .catch((err) => console.log(err));
  }, [refresh]);

  //------------Submenu handeling
  const [styleSubmenu, setStyleSubMenu] = useState({ ml: 2, display: "none" });
  const [styleSubmenuSubs, setStyleSubMenuSubs] = useState({
    ml: 2,
    display: "none",
  });
  const [styleSubmenuMisc, setStyleSubMenuMisc] = useState({
    ml: 2,
    display: "none",
  });
  const handleSubMenu = (menu) => {
    setValue(menu);
    const hide = { ml: 2, display: "none" };
    const unhide = { ml: 2, display: "box" };
    if (menu === "log") {
      setStyleSubMenu((prev) => {
        if (prev.display === "box") {
          return hide;
        }
        return unhide;
      });
    }
    if (menu === "subscriptionsInfo") {
      setStyleSubMenuSubs((prev) => {
        if (prev.display === "box") {
          return hide;
        }
        return unhide;
      });
    }
    if (menu === "misc") {
      setStyleSubMenuMisc((prev) => {
        if (prev.display === "box") {
          return hide;
        }
        return unhide;
      });
    }

    const myStyle = {
      basic: "",
      channels: "",
      payments: "",
      transfers: "",
      features: "",
      subscription: "",
      log: "",
      usageLog: "",
      cmLog: "",
      advanceCmLog: "",
      oldAdvanceCmLog: "",
      flexCollectLog: "",
      commissions: "",
      perfStats: "",
      airbnb: "",
      google: "",
      gha: "",
      group: "",
      groupReplenish: "",
      replenish: "",
      competitorRate: "",
      str: "",
      pricingAddon: "",
      subscriptionsInfo: "",
      misc: "",
    };
    myStyle[menu] = "primary";
    setmenuStyle(myStyle);
  };
  //------Handleling Menu---------

  const handleMenuItem = (menu) => {
    setValue(menu);
    const myStyle = {
      basic: "",
      channels: "",
      payments: "",
      transfers: "",
      features: "",
      subscription: "",
      log: "",
      usageLog: "",
      cmLog: "",
      advanceCmLog: "",
      oldAdvanceCmLog: "",
      flexCollectLog: "",
      commissions: "",
      perfStats: "",
      airbnb: "",
      google: "",
      gha: "",
      group: "",
      groupReplenish: "",
      replenish: "",
      competitorRate: "",
      str: "",
      pricingAddon: "",
      subscriptionsInfo: "",
      misc: "",
    };
    myStyle[menu] = "primary";
    setmenuStyle(myStyle);
  };
  //---------isIsha-----------
  const [isIsha, setIsIsha] = useState(false);

  const handleGetPropertyType = () => {
    handleSpringDataRequest(
      `core/api/v1/folio/get-property-type?hotelId=${hotelId}`
    )
      .then((res) => {
        if (res === "ISHA") {
          setIsIsha(true);
        }
      })
      .catch((err) => console.log(err));
  };

  //----------------PMS Accesscontroll---------------------
  const [accessControl, setAccessControll] = useState();
  const handlePmsAccessControll = () => {
    handleSpringDataRequest(
      `core/api/v1/accesscontrol/get-all-global-features?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) => {
        setAccessControll(data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    handleGetPropertyType();
    handlePmsAccessControll();
  }, []);

  const [RowsData, setRowsData] = useState([]);
  useEffect(() => {
    let dashboardUrl = "api/v2/metaAdmin/getMetaAdminMasterDashData/";
    handleDjangoDataRequest(dashboardUrl)
      .then((data) => {
        setRowsData(data?.hotelTableData);
      })
      .catch((err) => toast.error("Error "));
  }, []);

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          title={
            basicInfo &&
            `${basicInfo.hotel.hotel_name} : [${basicInfo.hotel_id}]`
          }
          // subheader={
          //   "Used for charging swiped cards or manually added cards in Folio"
          // }
        ></CardHeader>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Paper sx={{ minHeight: "90vh", height: "100%" }}>
            <MenuList>
              {dashboard === "group" ? (
                <>
                  <MenuItem onClick={() => handleMenuItem("group")}>
                    <ListItemIcon>
                      <DesignServicesIcon
                        fontSize="small"
                        color={menuStyle.group}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.group}>
                      Group Features
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItem("groupReplenish")}>
                    <ListItemIcon>
                      <AttractionsIcon
                        fontSize="small"
                        color={menuStyle.groupReplenish}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.groupReplenish}>
                      Group Auto Replenish
                    </Typography>
                  </MenuItem>
                </>
              ) : (
                <>
                  {access?.ManageBasicInfoForHotel && (
                    <MenuItem onClick={() => handleMenuItem("basic")}>
                      <ListItemIcon>
                        <InfoIcon fontSize="small" color={menuStyle.basic} />
                      </ListItemIcon>
                      <Typography color={menuStyle.basic}>
                        Basic Info
                      </Typography>
                    </MenuItem>
                  )}

                  {access?.ManageChannelMappingForHotel && (
                    <MenuItem onClick={() => handleMenuItem("channels")}>
                      <ListItemIcon>
                        <HubIcon fontSize="small" color={menuStyle.channels} />
                      </ListItemIcon>
                      <Typography color={menuStyle.channels}>
                        Channels
                      </Typography>
                    </MenuItem>
                  )}
                  {access?.ViewPaymentsForHotel && (
                    <MenuItem onClick={() => handleMenuItem("payments")}>
                      <ListItemIcon>
                        <PaymentsIcon
                          fontSize="small"
                          color={menuStyle.payments}
                        />
                      </ListItemIcon>
                      <Typography color={menuStyle.payments}>
                        Payments
                      </Typography>
                    </MenuItem>
                  )}
                  {access?.ViewTransfersForHotel && (
                    <MenuItem onClick={() => handleMenuItem("transfers")}>
                      <ListItemIcon>
                        <TransferWithinAStationIcon
                          fontSize="small"
                          color={menuStyle.transfers}
                        />
                      </ListItemIcon>
                      <Typography color={menuStyle.transfers}>
                        Transfers
                      </Typography>
                    </MenuItem>
                  )}
                  {access?.ManageFeaturesForHotel && (
                    <MenuItem onClick={() => handleMenuItem("features")}>
                      <ListItemIcon>
                        <DesignServicesIcon
                          fontSize="small"
                          color={menuStyle.features}
                        />
                      </ListItemIcon>
                      <Typography color={menuStyle.features}>
                        Features and restrictions
                      </Typography>
                    </MenuItem>
                  )}

                  <MenuItem onClick={() => handleSubMenu("subscriptionsInfo")}>
                    <ListItemIcon>
                      <BookIcon
                        fontSize="small"
                        color={menuStyle.subscriptionsInfo}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.subscriptionsInfo}>
                      Subscriptions info
                    </Typography>
                    <Typography color={menuStyle.subscriptionsInfo}>
                      <ArrowDropDownIcon />
                    </Typography>
                  </MenuItem>
                  {access?.ManageSubscriptionsForHotel && (
                    <MenuItem
                      sx={styleSubmenuSubs}
                      onClick={() => handleMenuItem("subscription")}
                    >
                      <ListItemIcon>
                        <HowToRegIcon
                          fontSize="small"
                          color={menuStyle.subscription}
                        />
                      </ListItemIcon>
                      <Typography color={menuStyle.subscription}>
                        Subscriptions
                      </Typography>
                    </MenuItem>
                  )}
                  {access?.ManageSubscriptionsForHotel && (
                    <MenuItem
                      sx={styleSubmenuSubs}
                      onClick={() => handleMenuItem("pricingAddon")}
                    >
                      <ListItemIcon>
                        <PrecisionManufacturingIcon
                          fontSize="small"
                          color={menuStyle.pricingAddon}
                        />
                      </ListItemIcon>

                      <Typography color={menuStyle.pricingAddon}>
                        Pricing addons
                      </Typography>
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => handleSubMenu("log")}>
                    <ListItemIcon>
                      <AssessmentIcon fontSize="small" color={menuStyle.log} />
                    </ListItemIcon>
                    <Typography color={menuStyle.log}>Logs</Typography>
                    <Typography color={menuStyle.log}>
                      <ArrowDropDownIcon />
                    </Typography>
                  </MenuItem>
                  {access?.ViewUsageLogForHotel && (
                    <MenuItem
                      sx={styleSubmenu}
                      onClick={() => handleMenuItem("usageLog")}
                    >
                      <ListItemIcon>
                        <PrecisionManufacturingIcon
                          fontSize="small"
                          color={menuStyle.usageLog}
                        />
                      </ListItemIcon>
                      <Typography color={menuStyle.usageLog}>
                        Usage Log
                      </Typography>
                    </MenuItem>
                  )}

                  <MenuItem
                    sx={styleSubmenu}
                    onClick={() => handleMenuItem("cmLog")}
                  >
                    <ListItemIcon>
                      <PatternIcon fontSize="small" color={menuStyle.cmLog} />
                    </ListItemIcon>
                    <Typography color={menuStyle.cmLog}>CM Log</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={styleSubmenu}
                    onClick={() => handleMenuItem("advanceCmLog")}
                  >
                    <ListItemIcon>
                      <LeaderboardIcon
                        fontSize="small"
                        color={menuStyle.advanceCmLog}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.advanceCmLog}>
                      Advance CM Log
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={styleSubmenu}
                    onClick={() => handleMenuItem("oldAdvanceCmLog")}
                  >
                    <ListItemIcon>
                      <AddchartIcon
                        fontSize="small"
                        color={menuStyle.oldAdvanceCmLog}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.oldAdvanceCmLog}>
                      Old Advance CM Log
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={styleSubmenu}
                    onClick={() => handleMenuItem("flexCollectLog")}
                  >
                    <ListItemIcon>
                      <CategoryIcon
                        fontSize="small"
                        color={menuStyle.flexCollectLog}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.flexCollectLog}>
                      Flex Collect Log
                    </Typography>
                  </MenuItem>
                  {/* {access?.ViewCommissionLogForHotel && (
                    <MenuItem onClick={() => handleMenuItem("commissions")}>
                      <ListItemIcon>
                        <RedeemIcon
                          fontSize="small"
                          color={menuStyle.commissions}
                        />
                      </ListItemIcon>
                      <Typography color={menuStyle.commissions}>
                        Commissions
                      </Typography>
                    </MenuItem>
                  )} */}
                  {/* {access?.Stats && (
                <MenuItem onClick={() => handleMenuItem("perfStats")}>
                  <ListItemIcon>
                    <QueryStatsIcon
                      fontSize="small"
                      color={menuStyle.perfStats}
                    />
                  </ListItemIcon>
                  <Typography  color={menuStyle.perfStats}>
                    Perf Stats
                  </Typography>
                </MenuItem>
              )} */}

                  <MenuItem onClick={() => handleMenuItem("airbnb")}>
                    <ListItemIcon>
                      <ContentPaste fontSize="small" color={menuStyle.airbnb} />
                    </ListItemIcon>
                    <Typography color={menuStyle.airbnb}>Airbnb</Typography>
                  </MenuItem>

                  <MenuItem onClick={() => handleSubMenu("misc")}>
                    <ListItemIcon>
                      <MiscellaneousServicesIcon
                        fontSize="small"
                        color={menuStyle.misc}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.misc}>Misc</Typography>
                    <Typography color={menuStyle.misc}>
                      <ArrowDropDownIcon />
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    sx={styleSubmenuMisc}
                    onClick={() => handleMenuItem("gha")}
                  >
                    <ListItemIcon>
                      <BubbleChartIcon fontSize="small" color={menuStyle.gha} />
                    </ListItemIcon>
                    <Typography color={menuStyle.gha}>
                      GHA Lowest Price
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    sx={styleSubmenuMisc}
                    onClick={() => handleMenuItem("google")}
                  >
                    <ListItemIcon>
                      <GoogleIcon fontSize="small" color={menuStyle.google} />
                    </ListItemIcon>
                    <Typography color={menuStyle.google}>Google</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={styleSubmenuMisc}
                    onClick={() => handleMenuItem("replenish")}
                  >
                    <ListItemIcon>
                      <AttractionsIcon
                        fontSize="small"
                        color={menuStyle.replenish}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.replenish}>
                      Auto Replenish
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    sx={styleSubmenuMisc}
                    onClick={() => handleMenuItem("competitorRate")}
                  >
                    <ListItemIcon>
                      <CompareIcon
                        fontSize="small"
                        color={menuStyle.competitorRate}
                      />
                    </ListItemIcon>
                    <Typography color={menuStyle.competitorRate}>
                      Rate competitor
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={styleSubmenuMisc}
                    onClick={() => handleMenuItem("str")}
                  >
                    <ListItemIcon>
                      <HandymanIcon fontSize="small" color={menuStyle.str} />
                    </ListItemIcon>
                    <Typography color={menuStyle.str}>
                      STR configuration
                    </Typography>
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Box>
            {value === "group" && <GroupFeatures hotelId={hotelId} />}
            {value === "groupReplenish" && (
              <GroupReplenish hotelId={hotelId} emailId={emailId} />
            )}
            {value === "replenish" && (
              <AutoReplenish hotelId={hotelId} emailId={emailId} />
            )}
            {value === "basic" && (
              <BasicInfo
                basicInfo={basicInfo}
                hotelId={hotelId}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            )}
            {value === "channels" && <Channels hotelId={hotelId} />}
            {value === "payments" && <Payments hotelId={hotelId} />}
            {value === "transfers" && <Transfers hotelId={hotelId} />}
            {value === "features" && (
              <Features
                hotelId={hotelId}
                basicInfo={basicInfo}
                setRefresh={setRefresh}
                isIsha={isIsha}
                accessControl={accessControl}
                groupId={groupId}
                emailId={emailId}
              />
            )}
            {(value === "subscription" || value === "subscriptionsInfo") && (
              <Subscriptions hotelId={hotelId} />
            )}
            {(value === "usageLog" || value === "log") && (
              <UsageLog hotelId={hotelId} />
            )}
            {value === "cmLog" && <CmLog hotelId={hotelId} />}
            {value === "advanceCmLog" && (
              <AdvanceCmLog hotelId={hotelId} isNewReport={true} />
            )}
            {value === "oldAdvanceCmLog" && (
              <AdvanceCmLog hotelId={hotelId} isNewReport={false} />
            )}
            {value === "flexCollectLog" && <FlexCollectLog hotelId={hotelId} />}
            {value === "commissions" && <Commissions hotelId={hotelId} />}
            {value === "perfStats" && <PerfStats hotelId={hotelId} />}
            {value === "airbnb" && <AirBnb hotelId={hotelId} />}
            {(value === "gha" || value === "misc") && (
              <GHAReport
                hotelId={hotelId}
                hotelName={basicInfo?.hotel.hotel_name}
              />
            )}
            {value === "google" && <Google hotelId={hotelId} />}

            {value === "competitorRate" && (
              <CompetitorRate
                hotelId={hotelId}
                hotelName={basicInfo?.hotel.hotel_name}
              />
            )}
            {value === "str" && (
              <STRConfiguration
                hotelId={hotelId}
                hotelName={basicInfo?.hotel.hotel_name}
              />
            )}
            {value === "pricingAddon" && (
              <AddonsPricing basicInfo={basicInfo} RowsData={RowsData} />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
